@import '../../styles/customMediaQueries.css';
.select {
  margin-top: 1rem;
  width: auto;
  @media (--viewportMedium) {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.normalSelect {
  width: 38px;
  /* margin-top: 17px; */
  font-size: 13px;
  font-weight: 600;
  border-bottom: none;
  color: var(--matterColor);
  @media (--viewportMedium) {
    width: 78px;
    font-size: 15px;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.normalSelectLandingPage {
  width: 50px;
  /* margin-top: 10px; */
  font-size: 15px;
  font-weight: 600;
  border-bottom: none;
  color: var(--matterColor);
  @media (--viewportMedium) {
    color: white;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.locationIcon {
  margin-top: 8px;
  margin-right: 0.25rem;
  font-size: 15px;
  margin-left: 0rem;
  @media (--viewportMedium) {
    margin-top: 16px;
    font-size: 18px;
    margin-right: -0.5rem;
    margin-left: 0.5rem;
  }
}
.label {
  font-size: 14px;
}
.languageComtainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  /* position: absolute; */
  right: 12%;
  z-index: 999;
}
.languageContainerC {
  display: flex;
  position: absolute;
  right: 7%;
  z-index: 999;
}
.mobile {
  display: flex;
  /* margin-left: 1rem; */
  z-index: 999;
  margin-top: 0.35rem;
}
.mobileLabel {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
.desktopLabel {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
