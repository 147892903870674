.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}
.weekPrice {
  margin-top: 1rem;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.priceGrid {
  margin: 2rem 0 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  justify-content: space-between;

  @media (--viewportMedium) {
    margin: 1rem 0;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }
}

.additionalPriceTitle,
.paymentMethods {
  margin-bottom: 0;
  line-height: 1.4;
}

.paymentMethods {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.typographyContainer {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.typography {
  margin: 0;
}

.body {
  line-height: 1.4;
}
.tip {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 0.5rem;
}
.tipnote {
  margin-top: 0;
  font-size: 14px;
  line-height: 1.4;
  color: var(--matterColorAnti);
}
.note {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  color: var(--matterColorAnti);
}
.addAnotherLanguage {
  color: #2ec8c8;
  cursor: pointer;
  width: auto;
  &:hover {
    color: #288787;
  }
}
.close {
  position: absolute;
  right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.delete {
  color: var(--failColor);
  cursor: pointer;
  &:hover {
    color: #c80000;
  }
}
.fieldArrayContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid lightgray;
  padding-bottom: 2.5rem;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.subContainer {
  display: flex;
}
.language {
  width: 95%;
  @media (--viewportMedium) {
    width: 100%;
  }
}
.level {
  width: 95%;
}
.languageHeading {
  font-size: 15px;
  font-weight: 600;
}
