@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.heroContent {
  padding: 1rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  left: 18%;
  top: 0%;

  @media (--viewportSmall) {
    left: 20%;
    top: 5%;
  }
  /* @media (min-width: 650px) {
    left: 20%;
    top: 10%;
  } */
  @media (--viewportMedium) {
    left: 20%;
    top: 0%;
  }
  @media (--viewportLarge) {
    left: 22%;
    top: 0%;
    /* height: 70%; */
  }
  @media (min-width: 1200px) {
    left: 22%;
    top: 0%;
  }
  @media (min-width: 1300px) {
    left: 24%;
    top: 5%;
  }
  @media (min-width: 1500px) {
    left: 30%;
    top: 5%;
  }
  @media (--viewportXLarge) {
    left: 30%;
    top: 20%;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  font-family: Inter Medium;
  composes: animation;

  animation-delay: 0.5s;
  letter-spacing: 2px;
  line-height: 1.1;
  font-size: 16px;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  @media (--viewportSmall) {
    line-height: 1.2;
    margin-top: 0;
    font-size: 27px;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  @media (--viewportMedium) {
    line-height: 40px;

    letter-spacing: 5px;
    font-size: 35px;
    margin-top: 0;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  @media (--viewportLarge) {
    line-height: 70px;
    letter-spacing: 5px;
    font-size: 45px;
    margin-bottom: 0;
    margin-top: 1.2rem;
  }
  @media (--viewportLargeWithPaddings) {
    line-height: 70px;
    letter-spacing: 5px;
    font-size: 50px;
    margin-bottom: 0;
    margin-top: 1.2rem;
  }
  @media (--viewportXLarge) {
    line-height: 70px;
    letter-spacing: 5px;
    font-size: 64px;
    margin-bottom: 0;
    margin-top: 1.2rem;
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  composes: animation;
  animation-delay: 0.65s;
  font-family: Inter Regular;
  font-size: 15px;
  line-height: 1.3;
  white-space: pre-line;
  max-width: 78%;
  @media (--viewportSmall) {
    line-height: 20px;
    font-size: 15px;
  }
  @media (--viewportMedium) {
    font-size: 18px;
    font-weight: 700;

    line-height: 45px;
    margin-top: 0.5rem;
  }
  @media (--viewportLarge) {
    font-size: 22px;
    font-weight: 700;

    line-height: 34px;
    margin-top: 2rem;
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 20px;
    font-weight: 700;

    line-height: 47px;
    margin-top: 2rem;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  margin-bottom: 1rem;
  animation-delay: 0.8s;
  margin-right: 1rem;
  border-radius: 6px;
  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
.filterButtons {
  display: block;

  @media (--viewportMedium) {
    display: flex;
  }
}
.heroImage {
  aspect-ratio: 4/2.1;
  width: 100%;
  height: 100%;
  border: 1px solid var(--matterColorNegative);
  transition: transform 0.3s;
  @media (min-width: 580px) {
    aspect-ratio: 4/2;
  }
  @media (min-width: 680px) {
    aspect-ratio: unset;
  }
  @media (--viewportLarge) {
    aspect-ratio: unset;
  }
}
.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--matterColorLight);
  padding: 1rem;
  padding-bottom: 3rem;
}
.bottomHeading {
  color: var(--marketplaceColor);
  margin-bottom: 0.5rem;
  text-align: center;
  font-family: Inter Medium;
  font-weight: 700;
  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 100px;
  }
}
.bottomText {
  margin: 0;
  text-align: center;
  font-family: Inter Regular;
  color: black;
  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 40px;
  }
}
