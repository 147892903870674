.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}
.inputSuccessLanding {
  border-bottom-color: var(--matterColorLight);
  &:hover {
    border-bottom-color: var(--matterColorLight);
  }
  &:focus {
    border-bottom-color: var(--matterColorLight);
  }
}
.label {
  font-size: 16px;
  font-weight: 600;
}
.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
.counter {
  margin: 0;
  color: #5c5c5c;
}
.inputContainer {
  position: relative;
}
.transalateComponent {
  position: absolute;
  right: 0%;
  /* bottom: 3px; */
}
