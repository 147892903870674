.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
.subHeading {
  font-size: 14px;
  margin: 0.25rem 0rem;
  color: gray;
  line-height: 1.4;
}
