.translateContainer {
  display: flex;
  justify-content: flex-end;
}
.container {
  /* position: absolute;
  right: 14%; */
}
.translateButton {
  width: 90px;
  min-height: 40px;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  font-weight: 500;
}
.icon {
  margin-bottom: 0.25rem;
  margin-right: 0.15rem;
}
