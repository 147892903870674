.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
  margin: 0;
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}
.esdescription {
  composes: formMargins;
  margin-bottom: 0.25rem;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.translateContainer {
  display: flex;
  justify-content: flex-end;
}
.translateButton {
  width: 150px;
  min-height: 40px;
  padding: 0.25rem 0.5rem;
}
.icon {
  margin-bottom: 0.25rem;
  margin-right: 0.15rem;
}
