@import '../../styles/propertySets.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);
  /* z-index: 12; */
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--matterColorLight);
  /* background: #F5F5F5; */
  /* shadows */
  /* box-shadow: var(--boxShadowLight); */
  position: relative;
  @media (--viewportLarge) {
    padding: 10px 20px;
  }
}
.greenShadow {
  display: none;

  @media (--viewportMedium) {
    display: block;
    z-index: 11;
    /* background: #f2f8ef; */
    height: 72px;
    position: absolute;

    width: 50%;
    right: 0;
    top: 0;
  }
}
/* logo */
.logoLink {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  padding: 10px 24px 21px 24px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 10px 36px 21px 36px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 45px;
  /* padding-bottom: 1rem; */
  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: fill;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  /* @apply --TopbarDesktop_linkHover; */
  @apply --marketplaceH5FontStyles;
  font-weight: 300;

  flex-shrink: 0;
  height: 100%;
  padding: 0 4px 0 4px;

  /* color: var(--marketplaceColor); */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    /* color: var(--marketplaceColorDark); */
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
  font-weight: 400;
  font-size: 16px;
  @media (--viewportLarge) {
    font-size: 17px;
  }
}

/* Inbox */
.inboxLink {
  /* @apply --TopbarDesktop_linkHover; */
  @apply --marketplaceH5FontStyles;
  font-weight: 300;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 0px 0 0px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  /* flex-shrink: 0; */
  max-height: 100%;

  /* padding: 0 12px 0 12px; */
  /* align-self: flex-end; */
  /* text-align: end; */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  @apply --marketplaceButtonStyles;
  align-self: center;
  padding: 6px;
  border-radius: 4.16px;
  max-width: 100px;
  height: fit-content;
  min-height: 20px;
  @media (--viewportMedium) {
    max-width: 120px;
    width: 120px;
  }
  margin-left: 10px;
}

.loginLink {
  flex-shrink: 0;
  align-self: flex-end;
  text-align: end;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--matterColorLight);
  border-radius: 4px;
  background-color: var(--marketplaceColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.signup,
.login {
  @apply --marketplaceH5FontStyles;
  font-size: 0.8rem;
  font-weight: 500;
  &:hover {
    background-color: var(--marketplaceColorDark);
  }
  /* @apply --TopbarDesktop_label; */
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-weight: 300;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.linksNew {
  display: flex;
  gap: 0.5rem;
  /* gap: 1.2rem; */
  @media (--viewportLargeWithPaddings) {
    gap: 0.25rem;
  }
}
.linksNewLanding {
  display: flex;
  gap: 0.5rem;
  /* gap: 1.2rem; */

  @media (--viewportLargeWithPaddings) {
    gap: 1rem;
  }
  @media (min-width: 1200px) {
    gap: 2.2rem;
  }
}
.root img,
.root button,
.root a,
.root p,
.root span,
.root h1,
.root h2,
.root h3,
.root h4 {
  @media (--viewportMedium) {
    z-index: 12;
    position: relative;
  }
}
.linksContainer {
  display: flex;
}
.normalSelect {
  display: block;
  @media (--viewportLarge) {
    display: none;
  }
}
.showDropDown {
  display: block;
  @media (min-width: 1250px) {
    display: none;
  }
}
.searchLinks {
  display: none;
  @media (min-width: 1250px) {
    display: flex;
    gap: 0.25rem;
  }
}
.downIcon {
  font-size: 30px;
}
