@import '../../styles/customMediaQueries.css';
.locationContainer {
  display: block;

  background: #ffffff;
  /* Secondary/Gray */
  margin-top: 1rem;
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  padding: 0.7rem 1rem;
  padding-bottom: 0.2rem;
  /* padding-top: 1rem; */
  z-index: 99;
  @media (--viewportMedium) {
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
    width: 95%;
  }
  @media (--viewportLarge) {
    width: 90%;
  }
}
.border {
  display: flex;
  justify-content: flex-end;
  background-color: lightgray;
  height: 100%;
  width: 2px;
}
.searchPredictions {
  position: absolute;
  width: 300px;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: var(--LocationAutocompleteInput_inputHeight);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}
.searchPredictionsAttribution {
}
.searchButton {
  min-height: 46px;
  background-color: var(--marketplaceColor);
  /* border: 1px solid var(--marketplaceColor); */
  color: var(--matterColorLight);
  /* width: 100%; */
  margin-top: 0.5rem;
  cursor: pointer;
  @media (--viewportMedium) {
    width: 140px;
    margin-top: 0;
  }
  &:hover {
    background-color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
  }
}
.label {
  margin: 0;
}

.landingPageSearch {
  margin: 2rem;
}

.searchField {
  margin-left: 0rem;
  margin-right: 1rem;
  border-bottom: 1px solid lightgrey;
  @media (--viewportMedium) {
    margin-left: 0.5rem;
    /* width: 50%; */
    /* margin-left: 2rem; */
    border-bottom: none;
  }
}

.searchInputIcon {
  box-sizing: content-box;
  padding-left: 24px;
  margin-top: 2px;

  display: none;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--matterColorLight);
  background-color: var(--matterColorLight);
}

.searchInput {
  flex-grow: 1;
  /* height: 69px; */
  padding-left: 0px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;
  border-bottom-color: var(--matterColorLight);
  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }
  @media (--viewportMedium) {
    padding-left: 10px;
  }
  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorLight);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    /* font-weight: var(--fontWeightRegular); */
    transition: var(--transitionStyleButton);
  }
}

.error {
  color: var(--failColor);
  display: none;
  font-size: 16px;
  margin: auto;
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorLight);
  width: fit-content;
  text-align: center;
  padding: 0rem 3rem;
  padding-bottom: 0.5rem;
  border-radius: 0px 0px 20px 20px;
  @media (--viewportMedium) {
    display: block;
  }
}
.errorMobile {
  color: var(--failColor);
  display: block;
  font-size: 16px;
  margin: auto;
  text-align: center;
  margin-top: 0.25rem;
  @media (--viewportMedium) {
    display: none;
  }
}
/* .dateField {
  border-left: none;
  @media (--viewportMedium) {
    border-left: 2px solid lightgray;
    padding-left: 0.5rem;
  }
} */

.labelOne {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  margin-left: 0;
  @media (--viewportMedium) {
    margin-top: -10px;
    margin-left: 0.6rem;
    margin-bottom: -10px;
  }
}
.label {
  margin: 0;
  font-weight: 500;
  font-size: 12px;

  @media (--viewportMedium) {
    margin-top: -10px;
    margin-bottom: -10px;
    /* margin-left: 0.6rem; */
  }
}
.fieldContainer {
  border-bottom: 1px solid lightgrey;

  @media (--viewportMedium) {
    border-bottom: none;
    border-left: 1px solid lightgray;
    padding-left: 0.5rem;
  }
}
input::placeholder {
  font-size: 14px;
}
.input {
}
.startDateRoot input {
  border-bottom-color: #ffffff;
}
