.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.title {
  margin-bottom: 24px;
}

.error {
  color: var(--failColor);
}

.calendarWrapper {
  flex-grow: 1;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }
  .title {
    margin-bottom: 32px;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
  .submitButton {
    display: inline-block;
    width: 241px;
    margin-top: 86px;
  }
}

.datePriceArrayContainer {
  padding: 20px 0px 30px 0px;
}
.datePriceArrayContainer {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 1rem;
  padding-top: 6px;
  padding-bottom: 10px;
  font-size: 20px;
}
.label {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding-top: 6px;
  padding-bottom: 10px;
  font-size: 20px;
}
.datePriceContent {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
  padding: 1rem;
  position: relative;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.datePriceContent .date,
.datePriceContent .price {
  flex-basis: 1;
  width: 100%;
  margin: 8px 25px 0 0;
  @media (--viewportMedium) {
    width: unset;
  }
}
.addButton {
  margin-top: 40px;
  width: auto;
  padding: 0 0.5rem;
  min-height: 50px;
  font-weight: 400;
}
.addButton:hover,
.addButton:focus {
  /* border: 2px dashed rgb(88, 87, 87);
  box-shadow: none;
  color: rgb(90, 88, 88);
  background: transparent; */
}
.experienceCloseContainer {
  /* display: flex;
  justify-content: flex-end; */
  position: absolute;
  right: 10%;
  z-index: 99;
  @media (--viewportMedium) {
    right: 15%;
  }
}

.close {
  width: 12px;
  height: 12px;
  stroke: var(--matterColorAnti);
  fill: var(--matterColorAnti);
  cursor: pointer;
}
.noPrice {
  font-weight: 500;
}
.note {
  line-height: 1.2;
  margin-top: 0.5rem;
}
.specificPriceNote {
  line-height: 1.2;
  color: var(--successColorDark);
}
.bufferTime {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
