@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 9;
}

