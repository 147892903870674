@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  /* gap: 3rem; */
  background-color: var(--matterColorLight);
  /* background: #f5f5f5; */
}

.heroContent {
  /* margin: 0 24px 0 24px; */
  width: 100%;
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('../../assets/backgroundNew.png');
  background-position: center center;
  background-repeat: no-repeat;
  /* color: #f5f5f5; */
  height: 75vh;
  max-height: 100vh;
  z-index: 9;
  /* margin-bottom: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 8rem; */
  text-align: center;
  @media (--viewportSmall) {
    height: 70vh;
  }
  @media (--viewportMedium) {
    height: 78vh;
  }
  @media (--viewportLarge) {
    text-align: unset;
    display: block;
    margin-top: 20px;
    color: inherit;
    height: fit-content;
    /* height: 100%; */
    padding-top: 0;
    /* width: 33%; */
    width: unset;
    background: none;
  }
  @media (--viewportLarge) {
    margin: 0;
    margin-right: 60px;
    width: 33%;
    /* margin: 0 7.5vw 0 7.5vw; */
  }

  /* Special viewport for adjusting the heroContent's placement */
}
.landingPageMobile {
  width: 90%;
  display: block;
  text-align: left;
  @media (--viewportMedium) {
    display: none;
  }
}
.heroImage {
  width: -webkit-fill-available;
  display: none;
  @media (--viewportLarge) {
    display: block;

    width: 56%;
  }
}
.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  /* color: var(--matterColorLight); */
  color: #f5f5f5;
  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportLarge) {
    color: inherit;
    line-height: 95px;

    margin-top: 0px;

    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;
  color: #f5f5f5;
  /* color: var(--matterColorLight); */
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportLarge) {
    color: var(--marketplaceColor);

    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  margin-bottom: 1rem;
  animation-delay: 0.8s;
  margin-right: 1rem;
  border-radius: 6px;
  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
.filterButtons {
  display: block;

  @media (--viewportMedium) {
    display: flex;
  }
}
.root img,
.root button,
.root a,
.root p,
.root span,
.root h1,
.root h2,
.root h3,
.root h4 {
  @media (--viewportMedium) {
    z-index: 9;
  }
}
