@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.bookingTypesContainer {
  margin-bottom: 1rem;
}

.error {
  color: var(--failColor);
  line-height: 1.4;
  margin: 0;
  font-size: 0.875rem;
}

.additionalPrices {
  /* margin-top: 2rem; */
}
.activities {
  margin-top: 2rem;
}
.activityPrice {
  font-size: 14px;
  font-weight: 600;
  color: var(--marketplaceColor);
}
.container {
  margin: 0 2rem;
  @media (--viewportMedium) {
    margin: 0;
  }
}
.packOfLessons {
  display: flex;
  /* align-items: flex-end; */
  justify-content: space-between;
  /* margin-bottom: 0.5rem; */
}
.packOfLessonsContainer {
  margin-top: 1rem;
  border-bottom: 1px solid var(--matterColorNegative);
}
.packOfLessonPrice {
  color: var(--marketplaceColor);
  font-weight: 600;
}
.lessonLabel {
  margin-bottom: 0.5rem;
}
.field {
  margin: 1rem 2rem;
  @media (--viewportMedium) {
    margin: 0;
    margin-bottom: 1rem;
  }
}
.ageCheck {
  margin-bottom: 1rem;
}
.age {
  font-size: 15px;
  line-height: 1.1;
  color: var(--matterColorAnti);
}
.extrasLabel {
  font-weight: 600;
  margin-bottom: 0;
}
.extraField {
  width: 60%;
  min-width: 60%;
}
.packOfLessonName {
}
