.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.label {
  font-size: 16px;
  font-weight: 600;
}
.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.policy {
  composes: formMargins;
  flex-shrink: 0;
}
.otherField {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.fieldArrayContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.subContainer {
  display: flex;
}
.level {
  width: 95%;
}
.close {
  margin-left: 0.5rem;
  margin-top: 1.4rem;
  width: auto;
}
.delete {
  color: var(--failColor);
  cursor: pointer;
  &:hover {
    color: #c80000;
  }
}
.addAnotherLanguage {
  color: #2ec8c8;
  cursor: pointer;
  width: auto;
  &:hover {
    color: #288787;
  }
}
.languageHeading {
  font-size: 15px;
}
.activities {
  margin-top: 1rem;
}
.note {
  color: #999;
}
