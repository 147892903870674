.root {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}
.rootLandingPage {
  width: 100%;
  /* position: sticky; */
  top: 0;
  z-index: var(--zIndexTopbar);
}
