.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
.container {
  display: flex;
  justify-content: space-between;
  @media (--viewportMedium) {
    width: 50%;
  }
}
.leftChild {
  margin-right: 1.5rem;
}
.rightChild {
  margin-right: 3rem;
  /* margin-top: 0.75rem; */
  @media (--viewportMedium) {
    margin-right: 0;
    /* margin-top: 0.75rem; */
  }
}
.label {
  font-size: 15px;
  margin-bottom: 0.75rem;
}
.subHeading {
  font-size: 13px;
  margin: 0;
  line-height: 1.4;
  color: gray;
  margin-bottom: 0.75rem;
}
.error {
  color: var(--failColor);
}
