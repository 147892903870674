@import '../../styles/propertySets.css';

.root {
  /* border-top-style: solid; */
  /* border-top-width: 1px; */
  /* border-top-color: var(--marketplaceColor); */
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;

  @media (--viewportMedium) {
    /* padding: 0.5rem 0.5rem 0.5rem 0.5rem; */
    padding: 0;
  }

  @media (--viewportLarge) {
    /* padding: 0.5rem 0.5rem 0.5rem 0.5rem; */

    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
  max-width: 1370px;
  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    /* max-width: calc(100vw - 200px); */

    padding: 0.4rem 0rem;
  }

  @media (--viewportXLarge) {
    max-width: calc(100vw - 400px);
    padding: 0.5rem 0rem;
  }
}
.linksLeftContainer {
  display: flex;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    padding: 0rem;
  }
}
.linksSocialContainer {
  justify-self: flex-end;
  margin-top: 1rem;
  @media (--viewportMedium) {
    justify-self: flex-start;
    margin-top: 0;

    padding: 1rem;
  }
}
.icon {
  color: var(--matterColorLight);
  margin: 0 8px;
}
.icon:hover {
  /* color: var(--marketplaceColorLight); */
}

.iconReal {
  color: var(--matterColorLight);
}
.iconReal:hover {
  color: var(--marketplaceColorLight);
}
.eachLink {
  margin: 0 1rem;
  margin-left: 0;
}

a.eachLink {
  color: var(--matterColorLight);
  font-size: small;
  font-weight: 300;
  font-size: 14px;
  font-weight: 500;
  line-height: 41px;
}
a.eachLink:hover {
  color: var(--marketplaceColorLight);
}
