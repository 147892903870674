@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (max-width: 550px) {
    align-items: center;
  }

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.policy {
  composes: formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.hint {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.submitButton {
  margin-top: 40px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (max-width: 560px) {
    margin-top: 4rem;
    width: 360px;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.buttons {
  margin-top: 3rem;
}
