.subHeading {
  color: hsl(0, 0%, 57%);
}
.subHeadingLabel {
  color: hwb(0 57% 43%);
  margin-left: 0.5rem;
}
.label {
  font-size: 16px;
  font-weight: 600;
}
