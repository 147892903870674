@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}
.infoStay {
  /* display: flex; */
  /* flex-direction: row; */
  padding: 16px 0 2px 0;
  position: relative;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.titleLanding {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.avtarContainer {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--matterColorBright);
  padding: 0.25rem 0.45rem;
  border-radius: 4px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.authorname {
  margin-left: 0.5rem;
  color: var(--matterColor);
}
.subTitle {
  margin: 0;
  font-size: 14px;
  color: var(--matterColor);
  font-weight: 400;
  line-height: 1.3;
}
.people {
  margin: 0;
  font-size: 14px;
  color: var(--matterColor);
  font-weight: 400;
  line-height: 1.3;
}
.duration {
  margin: 0;
  font-size: 14px;
  color: var(--matterColor);
  font-weight: 400;
  line-height: 1.3;
}
.green {
  color: var(--marketplaceColor);
  font-weight: 400;
}
.greenBold {
  color: var(--marketplaceColor);
  font-weight: 500;
}
.stayPriceLanding {
  display: flex;
  justify-content: space-between;
}
.stayPrice {
  display: flex;
  justify-content: space-between;
  margin: unset;
  @media (min-width: 1250px) {
    display: flex;
    justify-content: flex-end;
    gap: 2.5rem;
    /* margin-top: -1.2rem; */
  }
}
.stayLabel {
  margin: 0;
  font-size: 13px;
  color: var(--matterColor);
  margin-top: -6px;
  font-weight: 400;
  padding: 0;
}
