@import '../../styles/propertySets.css';

.root {
}
.title {
  @apply --marketplaceH2FontStyles;

  margin-top: 1rem;
  font-size: 1.6rem;

  margin-bottom: 2.8rem;
  line-height: 3rem;
  color: var(--marketplaceColor);
  @media (--viewportMedium) {
    /* max-width: 50%; */
    /* width: 300px; */
    margin-left: 2rem;
    word-spacing: 0.3rem;
  }
}

.buttonContainer {
  width: 100%;
  margin-top: 2.8rem;
}

.button {
  margin: 0 auto;
  width: 24%;
  min-width: 200px;
  min-height: 52px;
  position: relative;
  z-index: 9;
}
.buttonContainer a:hover {
  text-decoration: none;
}
.stepsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  /* flex-direction:column; */
  align-items: center;
  justify-content: center;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.eachStep {
  box-shadow: 8px 12px 28px 0px #02291614;
  background: white;
  width: 255px;
  height: 350px;
  padding: 1.5rem 1.3rem;
  /* padding: 2.5rem; */
  z-index: 9;
}
.bold {
  font-weight: 700;
}
.eachStepImage {
  box-shadow: 8px 12px 28px 0px #02291614;
  width: 245px;
  height: 350px;
  z-index: 9;
}
.eachStepImage img {
  height: 100%;
  width: 100%;
}
.eachStep {
  margin-bottom: 2.2rem;
}
.eachStepImage {
  margin-bottom: 2.2rem;
}
.eachStep p {
  font-weight: 200;
  /* line-height: 1.6; */
}
.border {
  display: none;
  @media (--viewportLarge) {
    display: block;
    width: 5rem;
    height: 4px;
    background-color: var(--marketplaceColor);
  }
}
.logo {
  margin-bottom: 0.5rem;
  width: 36px;
  height: 36px;
}
.subTitle {
  font-weight: 700;
  color: var(--marketplaceColor);
  font-size: 15px;
  /* font-size: 16px; */
  line-height: 25px;
  min-height: 50px;
  text-align: center;
  margin-bottom: 0;
}
.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-top: 0;
}
