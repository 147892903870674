.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.label {
  font-size: 16px;
  font-weight: 600;
}
.tip {
  margin: 0;
  line-height: 1.2;
}
.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.counter {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 22px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.counterContainer {
  margin-left: 1rem;
}
.tipnote {
  margin-top: 0;
  font-size: 14px;
  line-height: 1.4;
}
.priceInput {
  margin-bottom: 1.5rem;
}
.fieldArrayContainer {
  display: flex;
  gap: 1rem;
}
